@import './colors';

@mixin constricted {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin below_max_width {
  @media (max-width: 839px) {
    @content;
  }
}

@mixin max_width {
  @media (min-width: 840px) {
    @content;
  }
}



@mixin standard_side_margin {
  margin-left: 20px;
  margin-right: 20px;

  @include below_max_width {
    margin-left: 2vw;
    margin-right: 2vw;
  }

  @include constricted {
    margin-left: 0;
    margin-right: 0;
  }
}



@mixin nouveau_border($border_filename) {
  border-image-source: url("./assets/borders/#{$border_filename}");
  border-style: solid;
  border-color: $faded_gold;
  border-radius: 14px;

  border-image-slice: 33%;
  border-image-width: 166px;
  border-image-outset: 0;//16px;
  border-image-repeat: repeat;
}

@mixin parchment_background {
  background-image: url('./assets/bg_parchment.webp');
  background-color: #f6ebbf; // matches the image
}

@mixin red_button {
  color: white;
  text-decoration: none;
  text-align: center;
  width: fit-content;
  display: block;
  padding: 8px;
  border: none;
  border-radius: 8px;
  background-color: $rust;
  cursor: pointer;

  &:hover {
    background-color: $rust_light;
    color: white;
  }
  &:disabled {
    cursor: auto;
    background-color: $parchment_dark;
    color: $grey_mid;
  }
}

// @import url('https://fonts.googleapis.com/css?family=Bitter:regular,bold,italic&subset=latin,latin-ext');
// @import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

@font-face {
  font-family: 'Bitter';
  font-style: normal;
  src: local('Bitter'), url('./fonts/Bitter-VariableFont_wght.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Bitter';
  font-style: italic;
  src: local('Bitter'), url('./fonts/Bitter-Italic-VariableFont_wght.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Architects Daughter';
  src: local('Architects Daughter'), url('./fonts/ArchitectsDaughter-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'PT Mono';
  src: local('PT Mono'), url('./fonts/PTMono-Regular.ttf') format('truetype');
  font-display: swap;
}

@mixin text_default {
  font-family: 'Bitter', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  color: $soft_black;
}

@mixin text_monospace {
  font-family: 'PT Mono', monospace;
}

@mixin text_input {
  font-family: 'Bitter', serif;
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  color: $soft_black;
}

@mixin text_card_label {
  font-family: 'Bitter', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: $soft_black;
}


@mixin text_label {
  font-family: 'Bitter', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: $rust;
}

@mixin text_title_light {
  font-family: 'Bitter', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  color: $rust;
}

@mixin text_title_light_black {
  @include text_title_light;
  color: $soft_black;
}

@mixin text_title_heavy {
  font-family: 'Bitter', serif;
  font-size: 24px;
  font-style: italic;
  font-weight: bold;
  color: $rust;
}

@mixin text_title_heavy_black {
  @include text_title_heavy;
  color: $soft_black;
}

@mixin text_title_action {
  font-family: 'Bitter', serif;
  font-size: 30px;
  font-style: italic;
  font-weight: bold;
  color: $rust;
}

@mixin text_total {
  font-family: 'Bitter', serif;
  font-size: 94px;
  line-height: 1.0;
}

@mixin text_dicebag_summary {
  font-size: 22px;
}

@mixin text_dicebag_count {
  font-family: 'Bitter', serif;
  font-size: 36px;
}

@mixin text_connect_button {
  font-family: 'Bitter', serif;
  font-size: 20px;
  font-style: italic;
  // font-weight: bold;
  color: white;
}


@mixin text_party_roll_number {
  font-size: 16px;
}

@mixin text_party_roll_sum {
  font-size: 18px;
  font-weight: bold;
}

@mixin text_party_roll_condition {
  font-size: 16px;
  // font-weight: 500;
  font-style: italic;
}

@mixin text_party_roll_attackname {
  font-size: 16px;
  font-style: italic;
}

@mixin text_license {
  font-size: 14px;
}

@mixin text_legalese {
  font-size: 12px;
}

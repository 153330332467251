@import '../../fonts';

.ModeChooser {
  width: 100%;

  &.minimized { order: -1; }

  .roll-mode {
    a { cursor: pointer; }
    h2 { display: inline; }

    .mode-title {
      display: flex;
      align-items: center;
    }

    &.full {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 20px auto 100px;

      a {
        @include nouveau_border('nouveau_roller.svg');
        background-color: #ffdfd7; // light pink

        display: block;
        width: 340px;
        border-radius: 30px;
        padding: 40px 10px 30px;
        margin: 30px;
        color: $soft_black;
        text-decoration: none;

        &:hover { background-color: $rust_light; }

        .mode-title h2 {
          font-size: 54px;
          margin: 10px auto 16px;
        }

        p.mode-desc {
          font-size: 20px;
          text-align: center;
          margin: 0 40px 20px;
          line-height: 1.5;

        }

        input { display: none; }
      }
    }

    &.minimized {
      @include shadow;
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: $crushed_velvet;
      padding-bottom: 32px;
      margin-bottom: 6px;

      a {
        display: block;
        padding: 3px 0;
        width: 108px;
        // color: $soft_black;
        color: $pink;
        text-decoration: none;

        &:hover {
          background-color: $pink_logo_dark;
          color: white;
        }

        .mode-desc {
          display: none;
          cursor: auto;
        }

        &.selected {
          background-color: $rust_dark;
          color: white;
          cursor: auto;

          .mode-title h2 { font-weight: bold; }

          .mode-desc {
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            text-align: center;
            padding: 6px 0;
            margin: 3px 0;
            color: $pink;
            background-color: $rust_dark;
          }
        }

        .mode-title {
          display: flex;
          align-items: center;
          justify-content: center;

          h2 {
            margin: 2px 6px;
            font-size: 16px;
            font-weight: normal;
          }

          input { display: none;}
        }
      }
    }
  }
}

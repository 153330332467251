$pink: #daafa4;
$rust_light: #ce7a67;
$rust: #872f1b;
$rust_dark: #4b2021;

$concrete_violet_light: #beb3c3;
$concrete_violet: #948b98;
$concrete_violet_dark: #82707f;
$crushed_velvet: #593240;
$crushed_velvet_dark: #3d0a2e;

$olive_light: #a1c27c;
$olive_dark: #455800;

// c4dcaf
$green_witch: #00a76d;
$green_witch_mid: #45cc9d;
$green_witch_pale: #d1e6dd;
$green_witch_grey: #c1d6cc;

$pink_logo_light: #ecbfc2;
$pink_logo_dark: #aa4b4d;

$electric_orange: #ff3900;

$parchment: #fff6db;
$parchment_mid: #fae5be;
$parchment_dark: #dec9a3;
$mud_light: #bcab8a;
$mud: #7b705d;
$pumpkin: #dc9c43;

$periwinkle_very_light: #b7cdd7;
$periwinkle_light: #9ab8e1;
$periwinkle: #4b6faa;
$periwinkle_dark: #202552;

$grey_very_light: #eee;
$grey_light: #dedede;
$grey_mid_lighter: #959595;
$grey_mid_light: #b3b3b3;
$grey_mid: #777;
$grey_dark: #505050;
$grey_very_dark: #423e3e;
$grey_very_very_dark: #222;
$soft_black: #0f0a03;
$soft_white: #f1f1f1;

// match nouveau borders
$faded_gold: #f9cc9d;
$faded_grey: #e8dac1;
$faded_pink: #e5c4b1;
$faded_green: #b9d9cb; // b7d7b8

@mixin shadow {
  box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.15), 0 1px 2px 0 rgba(60, 64, 67, 0.3);
}

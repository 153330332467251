@import '../../colors';

.LoadinDots {
  margin: 250px auto;
  text-align: center;
  opacity: 0;
  animation: fadein 2s;
  animation-fill-mode: forwards;
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  padding-right: 50px;
  margin-top: 20px;
}

.lds-ellipsis div {
  position: absolute;
  top: 1px;
  left: -32px;
  width: 11px;
  height: 11px;
  border-radius: 50%;

  background: salmon;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

@keyframes fadein {
    0% { opacity: 0; }
    100%   { opacity: 1; }
}

@import 'assets';
@import 'fonts';

.App {
  @include text_default;
  -webkit-font-smoothing: antialiased;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  // overflow: hidden; // breaks sticky positioning
  min-height: 100vh;

  background-image: url('./assets/bg.webp');
  background-color: #987e8c;

  * { transition: background-color .075s ease-out; }

  .banner-and-chooser {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.reversed { flex-direction: column-reverse; }
  }

  // WITCH DICE BANNER
  .banner-container-container {
    width: 100%;
    max-width: 700px;
    margin-top: 10px;
    flex-shrink: 0;

    .banner-container {
      position: relative;
      width: 100%;
      padding-bottom: 29%;

      .asset {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
      }
    }
  }

  // WITCH CRAFT BANNER
  .witch-craft.banner-container-container {
    padding: 0 20px;
    margin-top: 16px;
    box-sizing: border-box;

    .banner-container {
      display: block;
      padding-bottom: 8%;
      border-radius: 20px;
      overflow: hidden;
    }
  }

  // LANCER BANNER
  .lancer.banner-container-container {
    padding: 0 20px;
    margin-top: 16px;
    box-sizing: border-box;

    .banner-container {
      display: block;
      padding-bottom: 14%;
      border-radius: 20px;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 430px) {
    h1.site-title { font-size: 28px; margin-top: 36px;}
  }

  a {
    color: seagreen;
    &:hover { color: olive }
  }

  strong { font-weight: bold; }
  em { font-style: italic; }

  input[type=checkbox], input[type=radio] {
    cursor: pointer;

    &:disabled {
      cursor: auto;
    }
  }

  button {
    @include text_default;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;

    &:disabled { cursor: auto; }
  }

  input[type=text] {
    @include text_input;
  }

  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  hr.pumpkin-bar {
    border: none;
    outline: 2px solid #7b5a54;
    // background-color: $pink_logo_light;
    background-color: $rust_dark;
    height: 7px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
}

@import '../../fonts';


.App .Footer {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;

  .meeeeeeeeeeeee-luv-u {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    margin-bottom: 0;

    span.asset {
      display: inline-block;
      width: 20px;
      height: 16px;
      margin-left: 2px;
      margin-top: 2px;
    }
  }

  p { margin: 8px 0; }

  > a { font-size: 14px; }

  a {
    text-decoration: none;
    margin: 6px 4px;
    cursor: pointer;

    // font-weight: bold;
    color: $rust_dark;
    &:hover { color: $soft_black; }
  }

  .open-button {
    font-size: 14px;
    cursor: pointer;

    // font-weight: bold;
    color: $rust_dark;
    &:hover { color: $soft_black; }
  }

  .feedback {
    font-size: 14px;
    margin: 0 4px;
  }

  .license {
    @include text_license;
    margin: 5px;

    .credits {
      @include shadow;
      @include parchment_background;
      @include nouveau_border('nouveau_roller.svg');

      box-sizing: border-box;
      max-width: 800px;
      padding: 10px 16px;
      margin: 10px auto;
      border-radius: 20px;

      p { margin: 8px 16px; }

      .noun-project {
        margin-top: 12px;

        a {margin-left: 6px;}
      }

      ul {
        text-align: left;
        margin: 0 0 10px;
        padding-left: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          margin: 0 10px 0 0;
          width: 30%;

          @media screen and (max-width: 480px) {
            width: 100%;
            margin: 0;
          }
        }
      }

      .legalese {
        @include text_legalese;
        text-align: left;
        padding: 8px 34px 40px;

        p {
          margin: 2px 0;
        }
      }
    }
  }
}

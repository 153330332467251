@import '../../fonts';


.ChangeLog {
  width: 100%;

  .beta-label {
    font-size: 14px;
    text-align: center;
    margin-bottom: 8px;
    cursor: pointer;

    // font-weight: bold;
    color: $rust_dark;
    &:hover { color: $soft_black; }
  }

  .change-container {
    @include shadow;
    @include parchment_background;
    @include nouveau_border('nouveau_character.svg');

    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    padding: 10px 16px 20px;
    margin: 10px auto;
    border-radius: 20px;

    .version-label {
      margin: 16px 0 10px;
    }

    ul {
      @include text_license;
      text-align: left;
      margin: 0;
      padding-left: 20px;

      li {
        margin-bottom: 4px;
      }
    }
  }

}

.asset {
  width: 64px;
  height: 64px;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;

  &.d4 { background-image: url('./assets/dice/d4.svg'); }
  &.d6 { background-image: url('./assets/dice/d6.svg'); }
  &.d8 { background-image: url('./assets/dice/d8.svg'); }
  &.d10 { background-image: url('./assets/dice/d10.svg'); }
  &.d12 { background-image: url('./assets/dice/d12.svg'); }
  &.d20 { background-image: url('./assets/dice/d20.svg'); }
  &.d0 { background-image: url('./assets/dice/d0.svg'); }
  &.d100 { background-image: url('./assets/dice/d100.svg'); }
  &.dx { background-image: url('./assets/dice/dx.svg'); }

  &.acid { background-image: url('./assets/types/acid.svg'); }
  &.bludgeoning { background-image: url('./assets/types/bludgeoning.svg'); }
  &.cold { background-image: url('./assets/types/cold.svg'); }
  &.fire { background-image: url('./assets/types/fire.svg'); }
  &.force { background-image: url('./assets/types/force.svg'); }
  &.lightning { background-image: url('./assets/types/lightning.svg'); }
  &.necrotic { background-image: url('./assets/types/necrotic.svg'); }
  &.piercing { background-image: url('./assets/types/piercing.svg'); }
  &.psychic { background-image: url('./assets/types/psychic.svg'); }
  &.poison { background-image: url('./assets/types/poison.svg'); }
  &.radiant { background-image: url('./assets/types/radiant.svg'); }
  &.slashing { background-image: url('./assets/types/slashing.svg'); }
  &.thunder { background-image: url('./assets/types/thunder.svg'); }

  &.flower { background-image: url('./assets/types/flower.svg'); }
  &.heart { background-image: url('./assets/heart.svg'); }

  &.crystals { background-image: url('./assets/crafting/crystals.svg'); }
  &.drafting { background-image: url('./assets/crafting/drafting.svg'); }
  &.livingarts { background-image: url('./assets/crafting/livingarts.svg'); }
  &.metals { background-image: url('./assets/crafting/metals.svg'); }
  &.textiles { background-image: url('./assets/crafting/textiles.svg'); }
  &.wood { background-image: url('./assets/crafting/wood.svg'); }

  &.plus { background-image: url('./assets/plus.svg'); }
  &.minus { background-image: url('./assets/minus.svg'); }
  &.checkmark { background-image: url('./assets/checkmark.svg'); }
  &.x { background-image: url('./assets/x.svg'); }
  &.x-thick { background-image: url('./assets/x_thick.svg'); }
  &.arrow { background-image: url('./assets/arrow.svg'); }
  &.arrow-sharp { background-image: url('./assets/arrow_sharp.svg'); }
  &.trash { background-image: url('./assets/trash.svg'); }
  &.export { background-image: url('./assets/export.svg'); }
  &.d20_frame { background-image: url('./assets/d20_frame.svg'); }
  &.bookmark { background-image: url('./assets/bookmark.svg'); }

  &.miss { background-image: url('./assets/roll_miss.svg'); }
  &.hit { background-image: url('./assets/roll_hit.svg'); }
  &.save { background-image: url('./assets/roll_save.svg'); }
  &.fail { background-image: url('./assets/roll_fail.svg'); }

  &.refresh { background-image: url('./assets/refresh.svg'); }
  &.edit { background-image: url('./assets/edit.svg'); }
  &.dot { background-image: url('./assets/dot.svg'); }
  &.dot_open { background-image: url('./assets/dot_open.svg'); }
  &.list_dot { background-image: url('./assets/list_dot.svg'); }
  &.list_check { background-image: url('./assets/list_check.svg'); }
  &.list_x { background-image: url('./assets/list_x.svg'); }

  &.owlbear_ext_icon { background-image: url('./assets/owlbear_ext_icon.svg'); }
  &.dicebag { background-image: url('./assets/dicebag.svg'); }
  &.gear { background-image: url('./assets/gear.svg'); }
  &.clock { background-image: url('./assets/clock.svg'); }
  &.list { background-image: url('./assets/list.svg'); }
  &.panel_expand { background-image: url('./assets/panel_expand.svg'); }
  &.panel_contract { background-image: url('./assets/panel_contract.svg'); }

  &.dna_left { background-image: url('./assets/dna_left.svg'); }
  &.dna_right { background-image: url('./assets/dna_right.svg'); }

  &.trans_pride { background-image: url('./assets/trans_pride.svg'); }
  &.olive_portrait { background-image: url('./assets/olive.png'); }
  &.kofi { background-image: url('./assets/kofi.png'); }
  &.discord { background-image: url('./assets/discord-logo-black.svg'); }
  &.owlbear { background-image: url('./assets/owlbear-logo.png'); }

  &.nouveau_divider { background-image: url('./assets/nouveau_divider.svg'); }

  &.logo { background-image: url('./assets/logo.svg'); }
  &.site_banner { background-image: url('./assets/banner_dark.svg'); }
  &.witchcraft_banner { background-image: url('./assets/witchcraft_banner.jpg'); }
  &.lancer_banner { background-image: url('./assets/lancer_banner.jpg'); }
}
